// @flow
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { graphql, navigate } from "gatsby";
import React from "react";
import styled from "styled-components";
import Footer from "../../components/Footer";
import GetStarted from "../../components/GetStarted";
import Header from "../../components/header";
import LearnMore from "../../components/LearnMore";
import Page from "../../components/Page";
import Section from "../../components/Section";
import { MEDIUM_BREAKPOINT, query } from "../../style";
import { purple } from "../../style/colors";
import {
  getAdvancedPracticeProviders,
  getPhysicalMedicineSpecialists,
  getPhysicians,
  getTherapists
} from "../../utils/functions";

import type {
  ImageSharp,
  Provider,
  ProviderList,
  ImageType
} from "../../types.js";

type Props = {
  data: {
    mainImage: ImageType,
    secondImage: ImageType,
    thirdImage: ImageType,
    teamImage: ImageType,
    allContentfulProvider: ProviderList
  }
};

const ProviderCard = ({ provider }: { provider: Provider }) => (
  <DocCard key={provider.name}>
    <StyledCardContent>
      <Horizontal>
        <Headshot
          src={provider.headshot.resolutions.base64}
          srcSet={provider.headshot.resolutions.srcSet}
        />
        <Vert>
          <Subtitle>{provider.type}</Subtitle>
          <Name>{provider.name}</Name>
        </Vert>
      </Horizontal>
      <CardBody>
        <Research>{provider.bioSummary}</Research>
      </CardBody>
    </StyledCardContent>
    <CardActions>
      <Button
        style={{ color: purple[500] }}
        onClick={() =>
          navigate(`/team/${provider.name.toLowerCase().replace(" ", "-")}`)
        }
      >
        Learn More
      </Button>
    </CardActions>
  </DocCard>
);

export default class Index extends React.Component<Props> {
  render() {
    const {
      mainImage,
      secondImage,
      thirdImage,
      allContentfulProvider
    } = this.props.data;
    return (
      <Page title="Meet our team">
        <Header
          mainImage={mainImage.childImageSharp}
          secondImage={secondImage.childImageSharp}
          thirdImage={thirdImage.childImageSharp}
          title="Meet our team"
          subtitle="Dealing with a spine problem can be a lonely and stressful experience; it doesn’t have to be that way. We commit to help you through your care and recovery, treating you as a person, not just a patient."
          cta="Make an appointment"
        />
        <StyledSection color="#D3EBFF">
          <Title>Multi-specialty care team</Title>
          <Body>
            At the Spine institute of Idaho, our goal is to be a single-source
            destination for your spine related needs, to do that, we have
            gathered a comprehensive care team, including: Physicians,
            Physiatrists, Physical Therapists, Nurse Practitioners and Physician
            Assistants. Each of our providers is highly trained and actively
            contributes to their field.
          </Body>
          <H4>Surgeons</H4>
          <Docs>
            {getPhysicians(allContentfulProvider).map(p => (
              <ProviderCard key={p.node.name} provider={p.node} />
            ))}
          </Docs>
          <H4>Physical Medicine & Rehabilitation Physicians</H4>
          <Docs>
            {getPhysicalMedicineSpecialists(allContentfulProvider).map(p => (
              <ProviderCard key={p.node.name} provider={p.node} />
            ))}
          </Docs>
          <H4>Advanced Practice Providers</H4>
          <Docs>
            {getAdvancedPracticeProviders(allContentfulProvider).map(p => (
              <ProviderCard key={p.node.name} provider={p.node} />
            ))}
          </Docs>
          <H4>Physical Therapists</H4>
          <Docs>
            {getTherapists(allContentfulProvider).map(p => (
              <ProviderCard key={p.node.name} provider={p.node} />
            ))}
          </Docs>
        </StyledSection>
        <LearnMore
          body="There is plenty that makes us different, but if you have any questions
          don't hesitate to call."
          team={false}
        />
        <GetStarted />
        <Footer />
      </Page>
    );
  }
}

const StyledSection = styled(Section)`
  && {
    ${query(MEDIUM_BREAKPOINT)`
      padding-top: 230px;
    `}
  }
`;

const H4 = styled.h4`
  && {
    text-transform: uppercase;
    text-align: center;
  }
`;

const DocCard = styled(Card)`
  && {
    min-width: 295px;
    margin: 10px 0px;
    ${query(510)`
      margin: 10px;
    `} ${query(MEDIUM_BREAKPOINT)`
      min-width: 320px;
    `};
    max-width: 405px;
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
  }
`;

const Horizontal = styled.div`
  && {
    display: flex;
    flex-direction: row;
  }
`;

const CardBody = styled.div`
  && {
    margin-top: 16px;
  }
`;

const DocPosition = styled.div`
  && {
  }
`;

const Research = styled.div`
  && {
  }
`;

const Subtitle = styled.div`
  && {
  }
`;

const Vert = styled.div`
  && {
  }
`;

const Name = styled.h3`
  && {
    flex: 1;
    margin-top: 10px;
  }
`;

const StyledCardContent = styled(CardContent)`
  && {
    flex: 1 0 auto;
  }
`;

const Headshot = styled.img`
  && {
    height: 80px;
    margin-right: 16px;
  }
`;

const Docs = styled.section`
  && {
    margin: 20px 0px 0 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Title = styled.h1`
  && {
    text-align: center;
  }
`;

const Body = styled.p`
  && {
    max-width: 500px;
    margin: auto;
    text-align: center;
  }
`;

export const pageQuery = graphql`
  query teamPhotos {
    mainImage: file(relativePath: { eq: "square/sari.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    secondImage: file(relativePath: { eq: "square/nurse.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    thirdImage: file(relativePath: { eq: "square/sam.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    allContentfulProvider(sort: { fields: order }) {
      edges {
        node {
          name
          bioSummary
          type
          order
          headshot {
            resolutions(quality: 70) {
              base64
              srcSet
              src
            }
          }
        }
      }
    }
  }
`;
